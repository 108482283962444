/*
*
* ==========================================
* 3RD PARTY | SWIEPR SLIDER
* ==========================================
*
*/
.swiper-button-prev,
.swiper-button-next {
  width: 3.5rem;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.5) !important;
  line-height: 3.3rem !important;
  transition: all 0.3s;
  outline: none;
  text-align: center;
  color: #fff;
  border-radius: 0;

  &.swiper-button-disabled {
    opacity: 0;
    z-index: -1;
  }

  span {
    color: #fff;
    font-size: 2.5rem;
  }

  &:hover {
    background: #b4346c !important;

    &:before {
      transform: none;
    }
  }

  &:after {
    font-size: 1rem;
  }

  &:before {
    content: "Prev";
    width: 5rem;
    height: 3.5rem;
    position: absolute;
    top: 0;
    right: -5rem;
    background: rgba(0, 0, 0, 0.8);
    line-height: 3.3rem;
    transition: all 0.3s;
    text-align: center;
    transform: rotateY(90deg);
    transform-origin: top left;
  }
}

.swiper-button-next::before {
  content: "Next";
  right: auto;
  left: -5rem;
  transform-origin: top right;
}

.swiper-pagination-bullet {
  background: #fff;
  width: 14px;
  height: 14px;
  border: 4px solid transparent;
  margin: 0 4px;
  opacity: 1;
}

.swiper-pagination-bullet-active,
.swiper-pagination-bullet:hover {
  transition: all 0.3s;
  background: none;
  border: 4px solid #b4346c;
}

.event-counter .day {
  border: 0;
  font-size: var(--bs-body-font-size) !important;
  display: block !important;
  padding: inherit !important;
}

span.bible-reference {
  display: block;
  margin-top: 0.5rem;
  font-style: italic;
}

@media screen and (max-width: 767.98px) {
  .swiper-fade .swiper-slide {
    min-height: 80vh;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .swiper-fade .swiper-slide {
    min-height: 80vh;
  }

  .swiper-button-prev:before,
  .swiper-button-next:before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar {
    background: #000000bb !important;
  }
}

.loader-video {
  background: url(../../../public/img/default-video-thumbnail.jpg);
  background-size: contain;
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-event {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.overflow-trim-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hero-slide.no-overlay {
  background-color: #0f0e1c;
  background-repeat: no-repeat;
}
.hero-slide.no-overlay::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.live-banner {
  background-image: url("../../../public/img/crowd.jpg");
  background-size: contain;
  position: relative;
  max-height: 9rem;
  height: 9rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #b4336a87;
    display: block;
    z-index: 0;
  }
  .live-container {
    z-index: 2;
    position: relative;
  }
  .live-streaming-img {
    height: 9rem;
    img {
      height: 9rem;
    }
  }
}

.special-home-hero {
  line-height: 0 !important;
  background: #05304b;
  overflow: hidden;
  position: relative;

  .video-top-mar {
    margin-top: 4.5rem;

    .floating-mute-icon {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }

  video {
    width: 100%;
  }

  // iframe {
  //   width: 100%;
  //   height: 100vh;
  // }
}

nav#navbar {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}
