.round-header-image {
  background: #fff;
  padding: 0.05rem;
  border-radius: 50%;
  margin: 0.05rem;
}

.round-footer-image {
  background: #fff;
  padding: 0.15rem;
  border-radius: 50%;
  margin: 0.5rem;
}
.social li.list-inline-item {
  font-size: 2rem;
  margin: 0.5rem;
}

.navbar-brand {
  margin-right: 0.35rem !important;
}

.app-loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  .spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #b4346c;
    -webkit-mask: repeating-conic-gradient(
        #0000 0deg,
        #000 1deg 70deg,
        #0000 71deg 90deg
      ),
      radial-gradient(
        farthest-side,
        #0000 calc(100% - 6px),
        #000 calc(100% - 4px)
      );
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s5 1s infinite;
    z-index: 0;
  }

  img {
    position: absolute;
    width: calc(100% - 20px);
    z-index: 1;
    top: 10px;
    left: 10px;
    animation: f1 1s 1s infinite;
  }
}
@keyframes s5 {
  to {
    transform: rotate(0.5turn);
  }
}
@keyframes f1 {
  100% {transform:perspective(300px) rotateY(360deg)}
}

.navbar-brand .church-name {
  font-size: 1.5rem !important;
}

.scroll-top.push-left {
  right: inherit;
  left: 1rem;
}