@media screen and (max-width: 767.98px) {
  .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) {
    order: 2;
  }

  .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
    flex-basis: 100%;
  }

  .fc .fc-toolbar .fc-toolbar-chunk {
    margin-top: 0.5rem;
  }

  .fc-header-toolbar.fc-toolbar {
    flex-wrap: wrap;
  }
}

.listMonth {
  .fc-listMonth-view {
    position: static !important;
    height: fit-content;
  }

  .fc .fc-scroller-liquid {
    height: fit-content;
  }

  .fc-view-harness {
    height: max-content !important;
  }
}

.fc-h-event {
  background-color: #B4346C;
  border-color: #B4346C;
}